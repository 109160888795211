<template>
  <v-dialog v-model="modalDetail" :width="CWidth" persistent>
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form" v-if="viewItem">
        <div class="pa-5">
          <v-col cols="12">
            <v-row class="mb-n9">
              <v-col cols="12">
                <v-text-field
                  v-model="viewItem.user_email"
                  outlined
                  dense
                  label="Email"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-n9">
              <v-col cols="12">
                <v-text-field
                  v-model="viewItem.user_nama"
                  outlined
                  dense
                  label="Nama Lengkap"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="viewItem.lvl_ket"
                  outlined
                  dense
                  label="Role"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalDetail from "@/store/user/modalDetail";

export default {
  computed: {
    modalDetail: {
      get() {
        return modalDetail.state.modalDetail;
      },
      set(value) {
        modalDetail.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalDetail.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalDetail() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    },
  },

  data: () => ({
    session: "",
    CWidth: "60%",
  }),

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/user/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/user/default-user.png";
      }
    },

    closeModal() {
      this.modalDetail = false;
    },
  },
};
</script>
