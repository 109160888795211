<template>
  <v-dialog v-model="modalEdit" :max-width="dekstop" persistent>
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit Data User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-row class="mb-n9">
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.user_email"
                    outlined
                    dense
                    :rules="fieldRules"
                    label="email"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="editedItem.user_nama"
                    outlined
                    dense
                    :rules="fieldRules"
                    label="Nama Lengkap"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="user_password"
                    outlined
                    dense
                    :rules="fieldRules"
                    @click:append="show = !show"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="editedItem.user_level"
                    outlined
                    dense
                    :items="refRole"
                    item-value="lvl_label"
                    item-text="lvl_ket"
                    :rules="fieldRules"
                    label="Role"
                    autocomplete="off"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="fieldfoto"
                    outlined
                    dense
                    prepend-icon
                    accept="image/png, image/jpeg, image/bmp"
                    label="Foto"
                    placeholder="Pilih File"
                    append-icon="mdi-camera"
                    @change="onFoto"
                  >
                  </v-file-input>
                </v-col>
              </v-row> -->
            </v-col>

            <!-- <v-col cols="12" md="4">
              <div class="text-center">
                <v-avatar size="230">
                  <v-img :src="urlImage" class="mx-auto"></v-img>
                </v-avatar>
              </div>
            </v-col> -->
          </v-row>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()">
              SIMPAN
            </v-btn>
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import modalEdit from "@/store/user/modalEdit";
import refreshView from "@/store/user/viewUser";

export default {
  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modalEdit.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refRole = await getRef.role(this.session.token);
      this.urlImage =
        this.editedItem.user_foto === ""
          ? process.env.VUE_APP_API_BASE + "upload/user/default-user.png"
          : process.env.VUE_APP_API_BASE +
            "upload/user/" +
            this.editedItem.user_foto;
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    btnLoading: true,
    show: false,

    fieldRules: [(value) => !!value || "Tidak Boleh Kosong"],
    refRole: [],
    fieldfoto: null,

    user_password: "",
    user_foto: "",
    urlImage: "",
  }),

  methods: {
    default() {
      this.fieldfoto = null;
      this.user_password = "";
      this.user_foto = "";
    },

    async edit() {
      this.btnLoading = false;

      const data = {
        user_id: this.editedItem.user_id,
        user_email: this.editedItem.user_email,
        user_nama: this.editedItem.user_nama,
        user_password: this.user_password,
        user_level: this.editedItem.user_level,
      };

      const url = process.env.VUE_APP_API + "user";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFoto(value) {
      this.user_foto = value;
      this.urlImage = URL.createObjectURL(this.user_foto);
    },

    closeModal() {
      this.default();
      this.modalEdit = false;
    },
  },
};
</script>
