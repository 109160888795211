<template>
  <v-dialog v-model="ModalAdd" :max-width="dekstop" persistent>
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="utama"
            fixed
            bottom
            right
            fab
            dark
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="utama" dense flat>
        <v-toolbar-title>Tambah Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="px-7 py-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-row class="mb-n9">
                <v-col cols="12">
                  <v-text-field
                    v-model="user_email"
                    outlined
                    dense
                    :rules="fieldRules"
                    label="Email"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="user_nama"
                    outlined
                    dense
                    :rules="fieldRules"
                    label="Nama User"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="user_password"
                    outlined
                    dense
                    :rules="fieldRules"
                    @click:append="show = !show"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="user_level"
                    outlined
                    dense
                    :items="refRole"
                    item-value="lvl_label"
                    item-text="lvl_ket"
                    :rules="fieldRules"
                    label="Role"
                    autocomplete="off"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="fieldfoto"
                    outlined
                    dense
                    prepend-icon
                    accept="image/png, image/jpeg, image/bmp"
                    label="Foto"
                    placeholder="Pilih File"
                    append-icon="mdi-camera"
                    @change="onFoto"
                  >
                  </v-file-input>
                </v-col>
              </v-row> -->
            </v-col>

            <!-- <v-col cols="12" md="4">
              <div class="text-center">
                <v-avatar size="230">
                  <v-img :src="urlImage" class="mx-auto"></v-img>
                </v-avatar>
              </div>
            </v-col> -->
          </v-row>

          <hr />
          <div class="text-right mt-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="simpan()"
              >Simpan</v-btn
            >
            <v-btn class="mr-2" v-else color="primary" depressed loading
              >Simpan</v-btn
            >
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";
import refreshView from "@/store/user/viewUser";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "60%",
    btnLoading: "true",
    show: false,
    ModalAdd: false,

    fieldRules: [(value) => !!value || "Tidak Boleh Kosong"],
    urlImage: process.env.VUE_APP_API_BASE + "upload/user/default-user.png",
    fieldfoto: null,

    refRole: [],

    user_email: "",
    user_nama: "",
    user_password: "",
    user_level: "",
    user_foto: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refRole = await getRef.role(this.session.token);
      this.ModalAdd = true;
    },

    default() {
      this.fieldfoto = null;
      this.user_email = "";
      this.user_nama = "";
      this.user_password = "";
      this.user_level = "";
      this.user_foto = "";
    },

    simpan() {
      this.btnLoading = false;

      const data = {
        user_email: this.user_email,
        user_nama: this.user_nama,
        user_password: this.user_password,
        user_level: this.user_level,
        user_foto: this.user_foto,
      };

      const url = process.env.VUE_APP_API + "user";
      this.axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFoto(value) {
      this.user_foto = value;
      this.urlImage = URL.createObjectURL(this.user_foto);
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },
  },
};
</script>
