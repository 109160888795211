import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        user: {
            user_id: '',
            user_nik: '',
            user_nama: '',
            user_username: '',
            role_id: '',
            user_foto: '',
            user_created_at: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.user = value
        }
    }
})
